import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { ApiSsoModule } from '@sharesafe/api/sso';
import {
  APPLICATION_INSIGHTS_INSTRUMENTATION_KEY, LOGIN_REDIRECT_URL,
  PLATFORM_CLIENT_ID,
  SharedModule,
  SSO_AUTH_HUB_PATH,
  SSO_CLIENT_ID,
  SSO_OAUTH_SCOPE,
  VORTEX_OAUTH_CONFIG
} from '@sharesafe/shared';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatProgressBarModule,

    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({ storage: StorageOption.SessionStorage }),
    NgxsFormPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: !environment.logStateChanges }),
    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'ShareSafe Solutions' }),

    SharedModule.forRoot(),
    AppRoutingModule,
    ApiSsoModule.forChild({
      baseUrl: environment.ssoUrl,
      clientId: environment.clientId
    })
  ],
  providers: [
    { provide: SSO_CLIENT_ID, useValue: environment.clientId },
    { provide: SSO_OAUTH_SCOPE, useValue: environment.clientScope },
    { provide: SSO_AUTH_HUB_PATH, useValue: 'hubs/authentication' },
    { provide: PLATFORM_CLIENT_ID, useValue: environment.platformClientId },
    { provide: VORTEX_OAUTH_CONFIG, useValue: environment.vortex },
    { provide: LOGIN_REDIRECT_URL, useValue: environment.loginRedirectUrl },
    { provide: APPLICATION_INSIGHTS_INSTRUMENTATION_KEY, useValue: environment.applicationInsights.instrumentationKey },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
