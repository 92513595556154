import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingOverlayService } from '@sharesafe/ui/shared';
import { ApiClientsModule } from './api-clients/api-clients.module';
import { ComponentsModule } from './components/components.module';
import { DirectivesModule } from './directives/directives.module';
import { AuthenticationGuard } from './guards';
import { AuthenticationInterceptor } from './interceptors/authentication/authentication.interceptor';
import { InterceptorsModule } from './interceptors/interceptors.module';
import { ServicesModule } from './services/services.module';
import { StateModule } from './state/state.module';
import { GlobalErrorHandlerService } from './services/global-error-handler/global-error-handler.service';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule,
    InterceptorsModule,
    ServicesModule,
    StateModule,
    MatSnackBarModule,
    ApiClientsModule
  ],
  exports: [
    ComponentsModule,
    DirectivesModule
  ]
})
export class SharedModule {

  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: AuthenticationGuard, useClass: AuthenticationGuard },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: LoadingOverlayService, useClass: LoadingOverlayService }
      ]
    };
  }
}
