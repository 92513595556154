import { Dictionary, LoginCredentials } from '@sharesafe/shared';

export namespace ApplicationActions {
  const NAMESPACE = '[Application]';

  export class SetQueryParams {
    public static readonly type = `${NAMESPACE} Set Query Parameters`;

    public constructor(public params: Dictionary) {
    }
  }

  export class ClearQueryParams {
    public static readonly type = `${NAMESPACE} Clear Query Parameters`;
  }

  export class LoginRedirect {
    public static readonly type = `${NAMESPACE} Login Redirect`;

    public constructor(public redirect: string | null) {
    }
  }

  export class ClearLoginRedirect {
    public static readonly type = `${NAMESPACE} Clear Login Redirect`;
  }

  export class RequirePlatformSelection {
    public static readonly type = `${NAMESPACE} Require Platform Selection`;

    public constructor(public requirePlatformSelection: boolean) {
    }
  }

  export class SetLoginCredentials {
    public static readonly type = `${NAMESPACE} Set Login Credentials`;

    public constructor(public credentials: LoginCredentials) {
    }
  }

  export class ClearLoginCredentials {
    public static readonly type = `${NAMESPACE} Clear Login Credentials`;
  }
}
