export interface PasswordResetRequirements {
  digit: boolean;
  lowerCase: boolean;
  upperCase: boolean;
  nonAlphanumeric: boolean;
  minimumLength: number;
}

export const DEFAULT_PASSWORD_RESET_REQUIREMENTS: PasswordResetRequirements = {
  digit: false,
  lowerCase: false,
  upperCase: false,
  nonAlphanumeric: false,
  minimumLength: 0
};

