import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subscription, TeardownLogic } from 'rxjs';

@Component({
  selector: 'shared-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseComponent implements OnDestroy {
  private readonly _subscription: Subscription = new Subscription();

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  protected set subscription(teardownLogic: TeardownLogic) {
    this._subscription.add(teardownLogic);
  }
}
