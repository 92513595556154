import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
// import {SSO_V1_API_BASE_URL} from '@sharesafe/singlesignon-api-client';
import {Observable} from 'rxjs';
import {TokenEndpointResponse} from '../../models';
import {SSO_CLIENT_ID, SSO_OAUTH_SCOPE} from '../../tokens';
import {ApiClientsModule} from '../api-clients.module';
import {BaseClient} from '../base.client';
import { SSO_API_URL } from '@sharesafe/api/sso';

@Injectable({
  providedIn: ApiClientsModule
})
export class OpenIdConnectClient extends BaseClient {
  private static readonly BasePath = 'connect';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     @Inject(SSO_CLIENT_ID) private clientId: string,
                     @Inject(SSO_OAUTH_SCOPE) private scope: string,
                     private httpClient: HttpClient) {
    super();
  }

  public loginCredentials(username: string,
                          password: string,
                          rememberMe: boolean = false,
                          scope?: string): Observable<TokenEndpointResponse> {
    return this.callTokenEndpoint({
      client_id: this.clientId,
      grant_type: 'password',
      username: username,
      password: password,
      remember_me: rememberMe,
      scope: scope || this.scope
    });
  }

  public getTokenWithMfa(providerName: string,
                         challenge: string,
                         rememberMe: boolean = false,
                         scope?: string): Observable<TokenEndpointResponse> {
    return this.callTokenEndpoint({
      client_id: this.clientId,
      grant_type: 'mfa',
      provider: providerName,
      challenge: challenge,
      remember_me: rememberMe,
      scope: scope || this.scope
    })
  }

  public getTokenFromBadgeScan(username: string, token: string, scope?: string): Observable<TokenEndpointResponse> {
    return this.callTokenEndpoint({
      client_id: this.clientId,
      grant_type: 'badge_scan',
      username: username,
      token: encodeURIComponent(token),
      scope: scope || this.scope
    });
  }

  public refreshToken(refreshToken: string): Observable<TokenEndpointResponse> {
    return this.callTokenEndpoint({
      client_id: this.clientId,
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    });
  }

  public logout(clientId: string, refreshToken: string): Observable<unknown> {
    const url = this.buildUrl(this.baseUrl, `${OpenIdConnectClient.BasePath}/logout`);
    const data = new HttpParams()
      .set('client_id', clientId)
      .set('refresh_token', refreshToken);

    return this.httpClient.post(url, data);
  }

  public callTokenEndpoint(params: Record<string, unknown>): Observable<TokenEndpointResponse> {
    const url = this.buildUrl(this.baseUrl, `${OpenIdConnectClient.BasePath}/token`);
    const data = Object.keys(params)
      .filter(k => params[k])
      .reduce((out: HttpParams, key: string) => {
        console.log(params[key])
        return out.append(key, `${params[key]}`);
      }, new HttpParams());

    return this.httpClient.post<TokenEndpointResponse>(url, data, {withCredentials: true});
  }
}
