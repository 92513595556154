import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from '@sharesafe/api/core';
import { Observable } from 'rxjs';
import { SSO_API_URL } from '../../constants/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { AuthenticationSystem, AuthenticationSystemOverview } from '@sharesafe/api/sso';

@Injectable()
export class AuthenticationSystemsApiService extends BaseApiService {
  private static readonly BasePath = 'api/authentication-systems';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getAuthenticationSystems(): Observable<AuthenticationSystemOverview[]> {
    const url = this.buildUrl(this.baseUrl, AuthenticationSystemsApiService.BasePath);

    return this.httpClient.get<AuthenticationSystem[]>(url);
  }

  public getAuthenticationSystem(id: number): Observable<AuthenticationSystem> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationSystemsApiService.BasePath}/${id}`);

    return this.httpClient.get<AuthenticationSystem>(url);
  }

  public createAuthenticationSystem(model: AuthenticationSystem): Observable<AuthenticationSystem> {
    const url = this.buildUrl(this.baseUrl, AuthenticationSystemsApiService.BasePath);

    // model.id = undefined;

    return this.httpClient.post<AuthenticationSystem>(url, model);
  }

  public updateAuthenticationSystem(model: AuthenticationSystem): Observable<AuthenticationSystem> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationSystemsApiService.BasePath}/${model.id}`);

    return this.httpClient.put<AuthenticationSystem>(url, model);
  }

  public deleteAuthenticationSystem(model: AuthenticationSystem): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationSystemsApiService.BasePath}/${model.id}`);

    return this.httpClient.delete(url);
  }
}
