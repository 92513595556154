import { Inject, Injectable, Optional } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { APPLICATION_INSIGHTS_INSTRUMENTATION_KEY } from '../../tokens';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private readonly appInsights?: ApplicationInsights;

  public constructor(@Inject(APPLICATION_INSIGHTS_INSTRUMENTATION_KEY) @Optional() private instrumentationKey: string) {
    if (this.instrumentationKey && this.instrumentationKey.length > 0) {
      this.appInsights = new ApplicationInsights({
        config: {
          appId: 'sso-webapp',
          enableAutoRouteTracking: true,
          instrumentationKey: this.instrumentationKey
        }
      });

      this.appInsights.loadAppInsights();
    }
  }

  public logError(message: string, stack: string | null): void {
    this.appInsights!?.trackException({
      exception: new Error(message)
    });

    this.appInsights?.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Error,
      properties: {
        stackTrace: stack
      }
    });

    console.log('LoggerService: ' + message);
  }
}
