import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {IdentitySelectors} from '../../state';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  public constructor(private store: Store) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hasValidAuth = !this.store.selectSnapshot(IdentitySelectors.expired);

    if (hasValidAuth) {
      request = this.addAuthHeaders(request);
    }

    return next.handle(request);
  }

  private addAuthHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const accessToken = this.store.selectSnapshot(IdentitySelectors.accessToken);

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}
