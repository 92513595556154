import { BaseApiService } from '@sharesafe/api/core';
import { Inject, Injectable } from '@angular/core';
import { SSO_API_URL } from '../../constants/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationSystem } from '../../models/authentication-system';

@Injectable()
export class AuthenticationClient extends BaseApiService {
  private static readonly BasePath = 'api/authentication';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getAuthenticationSystem(username: string): Observable<AuthenticationSystem> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationClient.BasePath}/system`, {
      username: username
    });

    return this.httpClient.get<AuthenticationSystem>(url);
  }

  public getTwoFactorProviders(): Observable<string[]> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationClient.BasePath}/mfa/providers`);

    return this.httpClient.get<string[]>(url, { withCredentials: true });
  }

  public getTwoFactorSecurityQuestion(): Observable<string> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationClient.BasePath}/mfa/providers/security-question`);

    return this.httpClient.get(url, { withCredentials: true, responseType: 'text' });
  }

  public sendTwoFactorVerificationCode(providerName: string): Observable<void> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationClient.BasePath}/mfa/providers/${providerName}`);

    return this.httpClient.post<void>(url, null, { withCredentials: true });
  }
}
