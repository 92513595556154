import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { NotificationDismissInterceptor } from './notification-dismiss/notification-dismiss.interceptor';

const interceptors = [
  AuthenticationInterceptor,
  NotificationDismissInterceptor
];

@NgModule({
  imports: [
    CommonModule
  ],
  providers: interceptors.map(i => ({ provide: HTTP_INTERCEPTORS, useClass: i, multi: true }))
})
export class InterceptorsModule {
}
