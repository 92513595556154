import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApplicationActions, IdentityActions, IdentitySelectors } from '@sharesafe/shared';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  public constructor(private store: Store,
                     private router: Router) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const isValid = this.store.selectSnapshot(IdentitySelectors.valid);
    const hasTokens = this.store.selectSnapshot(IdentitySelectors.hasTokens);

    if (isValid) {
      return true;
    }

    if (hasTokens) {
      return this.store.dispatch(new IdentityActions.RefreshTokens())
        .pipe(
          switchMap(() => this.store.selectOnce(IdentitySelectors.valid)),
          switchMap(valid => !valid ? this.redirectToLogin(state) : of(true)),
          catchError(() => this.handleRefreshTokenError(state))
        );
    }

    return this.redirectToLogin(state);
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(next, state);
  }

  private handleRefreshTokenError(state: RouterStateSnapshot): Observable<boolean> {
    return this.store.dispatch(new IdentityActions.ClearTokens())
      .pipe(
        switchMap(() => this.redirectToLogin(state))
      );
  }

  private redirectToLogin(state: RouterStateSnapshot): Observable<boolean> {
    let redirectUrl: string | null = state.url;

    if (!AuthenticationGuard.shouldStoreRedirect(redirectUrl)) {
      redirectUrl = null;
    }

    return this.store.dispatch([
      new IdentityActions.ClearTokens(),
      new ApplicationActions.LoginRedirect(redirectUrl)
    ]).pipe(
      map(() => this.router.navigate(['/login'])),
      switchMap(promise => from(promise)),
      map(() => false)
    );
  }

  private static shouldStoreRedirect(redirectUrl: string): boolean {
    const url = redirectUrl.toLowerCase();
    const badPaths: string[] = [
      'login/logout',
      'logout'
    ];

    for (const path of badPaths) {
      if (url.includes(path)) {
        return false;
      }
    }

    return true;
  }
}
