import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  public constructor() {
  }

  public getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No internet connection';
    }

    return error.message ? error.message : error.toString();
  }

  public getClientStackTrace(error: Error): string | null {
    return error.stack ?? null;
  }

  public getServerMessage(error: HttpErrorResponse): string {
    return error.message ?? null;
  }

  public getServerStackTrace(error: HttpErrorResponse): string | null {
    return null;
  }
}
