import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewUser, NewUserPlatforms, PlatformGroup, SecurityQuestion, User, UserSecurityQuestionsAnswers } from '../../models';
import { take } from 'rxjs/operators';
import { PlatformOverview, PlatformRegistrationInformation, SSO_API_URL } from '@sharesafe/api/sso';
import { BaseClient } from '../base.client';
import { ApiClientsModule } from '../api-clients.module';

@Injectable({
  providedIn: ApiClientsModule
})
export class RegistrationApiService extends BaseClient {
  private static readonly BasePath = 'api/register';

  constructor(@Inject(SSO_API_URL) private baseUrl: string,
              private httpClient: HttpClient) {
    super();
  }

  getPlatformGroups(): Observable<PlatformGroup[]> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/groups`);

    return this.httpClient.get<PlatformGroup[]>(url)
      .pipe(
        take(1)
      );
  }

  registerUser(model: NewUser): Observable<User> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/user`);

    return this.httpClient.post<User>(url, model)
      .pipe(
        take(1)
      );
  }

  resendEmailVerificationCode(userId: string): Observable<unknown> {
    return this.resendVerificationCode(userId, 'email');
  }

  resendSmsVerificationCode(userId: string): Observable<unknown> {
    return this.resendVerificationCode(userId, 'sms');
  }

  private resendVerificationCode(userId: string, method: string): Observable<unknown> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/user/${userId}/resend/${method}`);

    return this.httpClient.post(url, null);
  }

  verifyAccountWithEmail(userId: string, code: string): Observable<boolean> {
    return this.verifyAccount(userId, code, 'email');
  }

  verifyAccountWithSms(userId: string, code: string): Observable<boolean> {
    return this.verifyAccount(userId, code, 'sms');
  }

  private verifyAccount(userId: string, code: string, method: string): Observable<boolean> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/user/${userId}/verify/${method}`, {
      code: code
    });

    return this.httpClient.post<boolean>(url, null)
      .pipe(
        take(1)
      );
  }

  getAllSecurityQuestions(): Observable<SecurityQuestion[]> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/security-questions`);

    return this.httpClient.get<SecurityQuestion[]>(url)
      .pipe(
        take(1)
      );
  }

  createUserSecurityQuestionAnswers(userId: string, model: UserSecurityQuestionsAnswers): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/user/${userId}/security-questions/answers`);

    return this.httpClient.post(url, model)
      .pipe(
        take(1)
      );
  }

  getOpenPlatforms(): Observable<PlatformOverview[]> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/platforms/open`);

    return this.httpClient.get<PlatformOverview[]>(url)
      .pipe(
        take(1)
      );
  }

  getPlatformRegistrationInfo(linkId: string): Observable<PlatformRegistrationInformation> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/platforms/link/${linkId}`);

    return this.httpClient.get<PlatformRegistrationInformation>(url)
      .pipe(
        take(1)
      );
  }

  setUserPlatforms(userId: string, model: NewUserPlatforms): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${RegistrationApiService.BasePath}/user/${userId}/platforms`);

    return this.httpClient.post(url, model)
      .pipe(
        take(1)
      );
  }
}
