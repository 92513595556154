export const environment = {
  production: true,
  ssoUrl: 'https://sso.mysharesafe.com',
  logStateChanges: false,
  clientId: 'sso_webapp',
  clientSecret: 'not used',
  clientScope: 'openid profile offline_access platform',
  platformClientId: 'e905ba61-16a3-4d4d-b599-78aa1e5edb6f',
  vortex: {
    android: {
      clientId: '7eef8cdd-0e8b-47b6-bd27-77a7b390f2f5',
      redirectUri: 'vortex://',
      scope: 'openid profile offline_access platform'
    },
    iOS: {
      clientId: '7eef8cdd-0e8b-47b6-bd27-77a7b390f2f5',
      redirectUri: 'vortex://',
      scope: 'openid profile offline_access platform'
    }
  },
  loginRedirectUrl: 'https://links.mysharesafe.com/9FBF899F-36B0-439B-8E19-D38FC27E2970',
  applicationInsights: {
    instrumentationKey: 'a4fc7f92-f1cc-4836-87ab-3c117dd4e869'
  }
};
