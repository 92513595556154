import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'authorize',
    redirectTo: 'login/authorize'
  },
  {
    path: 'logout',
    redirectTo: 'login/logout'
  },
  {
    path: 'login',
    loadChildren: () => import('login/feature-shell').then(m => m.LoginFeatureShellModule)
  },
  {
    path: 'register',
    loadChildren: () => import('registration/feature-shell').then(m => m.RegistrationFeatureShellModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
