import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxsModule} from '@ngxs/store';
import {ApplicationState} from './application/application.state';
import {IdentityState} from './identity/identity.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([ApplicationState, IdentityState])
  ]
})
export class StateModule {
}
