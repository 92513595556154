import { Selector } from '@ngxs/store';
import { ApplicationState } from './application.state';
import { ApplicationStateModel } from './application.state-model';
import { Dictionary } from '@sharesafe/shared';

export class ApplicationSelectors {

  @Selector([ApplicationSelectors.queryParams])
  public static hasQueryParams(queryParams: Dictionary): boolean {
    return Object.keys(queryParams).length > 1;
  }

  @Selector([ApplicationState])
  public static queryParams(state: ApplicationStateModel): Dictionary {
    return state.queryParams || {};
  }

  @Selector([ApplicationState])
  public static loginRedirect(state: ApplicationStateModel): string | null {
    return state.loginRedirect;
  }

  @Selector([ApplicationState])
  public static requirePlatformSelection(state: ApplicationStateModel): boolean {
    return state.requirePlatformSelection;
  }
}
