import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Action, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { AuthenticationApiService, UsersApiService } from '@sharesafe/api/sso';
import * as moment from 'moment';
import { OpenIdConnectClient, RegistrationApiService } from '../../api-clients';
import { TokenEndpointResponse } from '../../models';
// noinspection ES6PreferShortImport
import { IdentityActions } from './identity.actions';
import { IdentityStateModel } from './identity.state-model';
import { IDENTITY_STATE_TOKEN } from './identity.state-token';

const DEFAULT_STATE: IdentityStateModel = {
  userId: undefined,
  fullName: undefined,
  username: undefined,
  photoUrl: undefined,
  accessToken: undefined,
  idToken: undefined,
  refreshToken: undefined,
  expires: undefined,
  platforms: [],
  preferredPlatformId: undefined,
  registration: {
    openPlatforms: [],
    selected: []
  }
};

type Context = StateContext<IdentityStateModel>;

@State<IdentityStateModel>({
  name: IDENTITY_STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class IdentityState {
  private readonly _jwt: JwtHelperService = new JwtHelperService();

  public constructor(private openIdConnectClient: OpenIdConnectClient,
                     private authService: AuthenticationApiService,
                     private userService: UsersApiService,
                     private registrationApiService: RegistrationApiService) {
  }

  @Action(IdentityActions.SetTokens)
  public setIdentity(context: Context, { accessToken, idToken, refreshToken, expiresIn }: IdentityActions.SetTokens) {
    const identity = this._jwt.decodeToken(idToken);
    const expiration = moment().utc().add(expiresIn, 'seconds');

    context.patchState({
      userId: identity?.sub,
      fullName: identity?.name,
      username: identity?.username,
      photoUrl: identity?.picture,
      accessToken: accessToken,
      idToken: idToken,
      refreshToken: refreshToken,
      expires: expiration.toDate()
    });
  }

  @Action(IdentityActions.ClearTokens)
  public clearTokens(context: Context) {
    context.patchState({
      userId: undefined,
      fullName: undefined,
      photoUrl: undefined,
      accessToken: undefined,
      idToken: undefined,
      refreshToken: undefined,
      expires: undefined
    });
  }

  @Action(IdentityActions.RefreshTokens)
  public async refreshTokens(context: Context) {
    const state = context.getState();

    if (!state.refreshToken) {
      throw new Error('Invalid refresh token.');
    }

    let tokens: TokenEndpointResponse | null = null;

    try {
      tokens = await this.openIdConnectClient.refreshToken(state.refreshToken).toPromise();
    } catch (error) {
      context.dispatch(new IdentityActions.Reset());

      throw error;
    }

    if (tokens) {
      context.dispatch(new IdentityActions.SetTokens(tokens.access_token, tokens.id_token, tokens.refresh_token, tokens.expires_in));
    }
  }

  @Action(IdentityActions.Reset)
  public reset(context: Context) {
    context.patchState(DEFAULT_STATE);
  }


  @Action(IdentityActions.GetUserPlatforms)
  public async getUserPlatforms(context: Context) {
    const response = await this.authService.getUserPlatforms().toPromise();

    context.patchState({
      preferredPlatformId: response.preferredPlatformId,
      platforms: response.platforms
    });
  }

  @Action(IdentityActions.SetPreferredPlatform)
  public async setPreferredPlatform(context: Context, { platformId }: IdentityActions.SetPreferredPlatform) {
    await this.userService.setPreferredPlatform(platformId).toPromise();

    context.patchState({
      preferredPlatformId: platformId
    });
  }

  @Action(IdentityActions.ClearPlatforms)
  public clearPlatforms(context: Context) {
    context.patchState({
      platforms: [],
      preferredPlatformId: undefined
    });
  }

  @Action(IdentityActions.GetOpenRegistrationPlatforms)
  public async getOpenRegistrationPlatforms(context: Context) {
    const platforms = await this.registrationApiService.getOpenPlatforms().toPromise();

    context.patchState({
      registration: {
        openPlatforms: platforms,
        selected: []
      }
    });
  }

  @Action(IdentityActions.SelectRegistrationPlatform)
  public selectRegistrationPlatform(context: Context, { platformId }: IdentityActions.SelectRegistrationPlatform) {
    const selected = !!context.getState().registration.selected.find(id => platformId === id);

    context.setState(
      patch({
        registration: patch({
          selected: selected ? removeItem(id => id === platformId) : insertItem(platformId)
        })
      })
    );
  }
}
