import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from '@sharesafe/api/core';
import { SSO_API_URL } from '../../constants/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UsersApiService extends BaseApiService {
  private static readonly BasePath = 'api/user';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public setPreferredPlatform(platformId: number): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${UsersApiService.BasePath}/platform/preferred/${platformId}`);

    return this.httpClient.post(url, null);
  }

  public registerPlatform(platformId: number): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${UsersApiService.BasePath}/platform/${platformId}/register`);

    return this.httpClient.post(url, null);
  }
}
