export abstract class BaseClient {

  protected buildUrl(baseUrl: string, apiMethod: string, params?: Record<string, unknown>): string {
    const apiBaseUrl = baseUrl.replace(/\/$/, '').trim();
    const url = `${apiBaseUrl}/${apiMethod}`;
    const query = this.buildQueryParams(params);

    if (query && query.length > 0) {
      return `${url}?${query}`;
    }

    return url;
  }

  protected buildQueryParams(params?: Record<string, unknown>): string | undefined {
    if (!params) {
      return undefined;
    }

    return Object.keys(params)
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&');
  }
}
