import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseComponent} from './base/base.component';
import {AppLogoComponent} from './app-logo/app-logo.component';
import {DirectivesModule} from '../directives/directives.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [BaseComponent, AppLogoComponent],
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule
  ],
  exports: [BaseComponent, AppLogoComponent]
})
export class ComponentsModule {
}
