// noinspection ES6PreferShortImport

import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { ApplicationStateModel } from './application.state-model';
import { APPLICATION_STATE_TOKEN } from './application.state-token';
import { ApplicationActions } from './application.actions';

const DEFAULT_STATE: ApplicationStateModel = {
  queryParams: {},
  loginCredentials: null,
  loginRedirect: null,
  requirePlatformSelection: false
};

type Context = StateContext<ApplicationStateModel>;

@State<ApplicationStateModel>({
  name: APPLICATION_STATE_TOKEN,
  defaults: DEFAULT_STATE
})
@Injectable()
export class ApplicationState {

  @Action(ApplicationActions.SetQueryParams)
  public setQueryParams(context: Context, { params }: ApplicationActions.SetQueryParams) {
    if (Object.keys(params).length > 0) {
      context.patchState({ queryParams: params });
    }
  }

  @Action(ApplicationActions.ClearQueryParams)
  public clearQueryParams(context: Context) {
    context.patchState(DEFAULT_STATE);
  }

  @Action(ApplicationActions.LoginRedirect)
  public loginRedirect(context: Context, { redirect }: ApplicationActions.LoginRedirect) {
    context.patchState({
      loginRedirect: redirect
    });
  }

  @Action(ApplicationActions.ClearLoginRedirect)
  public clearLoginRedirect(context: Context) {
    context.patchState({
      loginRedirect: null
    });
  }

  @Action(ApplicationActions.RequirePlatformSelection)
  public requirePlatformSelection(context: Context, { requirePlatformSelection }: ApplicationActions.RequirePlatformSelection) {
    context.patchState({
      requirePlatformSelection: requirePlatformSelection
    });
  }

  @Action(ApplicationActions.SetLoginCredentials)
  public setLoginCredentials(context: Context, { credentials }: ApplicationActions.SetLoginCredentials) {

    context.patchState({
      loginCredentials: {
        username: credentials.username,
        password: credentials.password
      }
    });
  }

  @Action(ApplicationActions.ClearLoginCredentials)
  public clearLoginCredentials(context: Context) {
    context.patchState({
      loginCredentials: undefined
    });
  }
}
