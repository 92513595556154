import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { BaseApiService } from '@sharesafe/api/core';

import { SSO_API_URL } from '../../constants/injection-tokens';
import { AuthenticationSystemOverview, LoginConfirmationMethods, QrSession, SecurityQuestion, UserPlatforms } from '../../..';

@Injectable()
export class AuthenticationApiService extends BaseApiService {
  private static readonly BasePath = 'api/auth';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getQrSession(): Observable<QrSession> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code`);

    return this.httpClient.get<QrSession>(url);
  }

  public getQrCodeUrl(code: string): string {
    return this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code/${code}`);
  }

  public getConfirmationMethods(code: string): Observable<LoginConfirmationMethods> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code/${code}/confirm/methods`);

    return this.httpClient.get<LoginConfirmationMethods>(url, {withCredentials: true});
  }

  public sendConfirmationCodeEmail(code: string): Observable<any> {
    return this.sendConfirmationCodeMethod(code, 'email');
  }

  public sendConfirmationCodeSms(code: string): Observable<any> {
    return this.sendConfirmationCodeMethod(code, 'sms');
  }

  private sendConfirmationCodeMethod(code: string, method: string): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code/${code}/confirm/${method}`);

    return this.httpClient.post(url, null);
  }

  public getSecurityQuestion(code: string): Observable<SecurityQuestion> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code/${code}/confirm/question`);

    return this.httpClient.get<SecurityQuestion>(url);
  }

  public checkConfirmed(code: string): Observable<QrSession> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/qr-code/${code}/confirmed`);

    return this.httpClient.get<QrSession>(url);
  }

  public getAuthenticationMethod(username: string): Observable<AuthenticationSystemOverview | null> {
    if (!username) {
      return of(null);
    }

    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/user/${username}/authentication-methods`);

    return this.httpClient.get<AuthenticationSystemOverview>(url);
  }

  public getUserPlatforms(): Observable<UserPlatforms> {
    const url = this.buildUrl(this.baseUrl, `${AuthenticationApiService.BasePath}/user/platforms`);

    return this.httpClient.get<UserPlatforms>(url, { withCredentials: true });
  }
}
