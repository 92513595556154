import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LazyLoadDirective } from './lazy-load/lazy-load.directive';
import { NgLetDirective } from './ng-let/ng-let.directive';
import { RouterLinkDisabledDirective } from './router-link-disabled/router-link-disabled.directive';

@NgModule({
  declarations: [NgLetDirective, RouterLinkDisabledDirective, LazyLoadDirective],
  imports: [
    CommonModule
  ],
  exports: [NgLetDirective, RouterLinkDisabledDirective, LazyLoadDirective]
})
export class DirectivesModule {
}
