import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from '../error';
import { LoggerService } from '../logger';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  public constructor(private injector: Injector) {
  }

  private get errorService(): ErrorService {
    return this.injector.get(ErrorService);
  }

  private get loggerService(): LoggerService {
    return this.injector.get(LoggerService);
  }

  public handleError(error: Error | HttpErrorResponse): void {
    let message: string;
    let stackTrace: string | null;

    if (error instanceof HttpErrorResponse) {
      message = this.errorService.getServerMessage(error);
      stackTrace = this.errorService.getServerStackTrace(error);
    } else {
      message = this.errorService.getClientMessage(error);
      stackTrace = this.errorService.getClientStackTrace(error);
    }

    this.loggerService.logError(message, stackTrace);
    console.error(error);
  }
}
