import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _snackBarRef: MatSnackBarRef<unknown> | null = null;

  public constructor(private snackBar: MatSnackBar) {
  }

  public open(message: string, action: string = 'Dismiss', duration: number = 10): Observable<MatSnackBarDismiss> {
    this.close();

    this._snackBarRef = this.snackBar.open(message, action, {
      duration: duration * 1000,
      verticalPosition: 'top',
      panelClass: 'notification-important'
    });

    return this._snackBarRef.afterDismissed();
  }

  public close(): void {
    if (!this._snackBarRef) {
      return;
    }

    this._snackBarRef.dismiss();
    this._snackBarRef = null;
  }
}
