import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from '@sharesafe/api/core';

import { SSO_API_CLIENT_ID, SSO_API_URL } from '../../constants/injection-tokens';
import { TokenResponse } from '../../..';
import { Dictionary } from '@sharesafe/shared';

@Injectable()
export class OpenidConnectApiService extends BaseApiService {
  private static readonly BasePath = 'connect';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     @Inject(SSO_API_CLIENT_ID) private clientId: string,
                     private httpClient: HttpClient) {
    super();
  }

  public loginCredentials(username: string, password: string, code: string, scope: string): Observable<TokenResponse> {
    return this.token({
      username,
      password,
      code,
      grant_type: 'password',
      client_id: this.clientId,
      scope
    });
  }

  public token(params: Dictionary): Observable<TokenResponse> {
    const url = this.buildUrl(this.baseUrl, `${OpenidConnectApiService.BasePath}/token`);
    let data = new HttpParams();

    for (const key of Object.keys(params).filter(k => params[k])) {
      data = data.append(key, params[key]);
    }

    return this.httpClient.post<TokenResponse>(url, data, {withCredentials: true});
  }

  public logout(clientId: string, refreshToken: string): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${OpenidConnectApiService.BasePath}/logout`);
    const data = new HttpParams()
      .set('client_id', clientId)
      .set('refresh_token', refreshToken);

    return this.httpClient.post(url, data);
  }
}
