import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from '@sharesafe/api/core';

import { SSO_API_URL } from '../../constants/injection-tokens';

@Injectable()
export class ClientsApiService extends BaseApiService {
  private static readonly BasePath = 'api/clients';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getRedirectMessage(clientId: string): Observable<string> {
    const url = this.buildUrl(this.baseUrl, `${ClientsApiService.BasePath}/${clientId}/redirect-message`);

    return this.httpClient.get(url, {
      responseType: 'text'
    });
  }
}
