import { LoginCredentials } from 'login/domain';

export namespace IdentityActions {
  const NAMESPACE = '[Identity]';

  export class SetLoginCredentials {
    public static readonly type = `${NAMESPACE} Set Login Credentials`;

    public constructor(public credentials: LoginCredentials) {
    }
  }

  export class SetTokens {
    public static readonly type = `${NAMESPACE} Set Identity`;

    public constructor(public accessToken: string, public idToken: string, public refreshToken: string, public expiresIn: number) {
    }
  }

  export class ClearTokens {
    public static readonly type = `${NAMESPACE} Clear Identity`;
  }

  export class RefreshTokens {
    public static readonly type = `${NAMESPACE} Refresh Tokens`;
  }

  export class Reset {
    public static readonly type = `${NAMESPACE} Reset`;
  }

  export class GetUserPlatforms {
    public static readonly type = `${NAMESPACE} Get User Platforms`;
  }

  export class SetPreferredPlatform {
    public static readonly type = `${NAMESPACE} Set Preferred Platform`;

    public constructor(public platformId: number) {
    }
  }

  export class ClearPlatforms {
    public static readonly type = `${NAMESPACE} Clear Platforms`;
  }

  export class GetOpenRegistrationPlatforms {
    public static readonly type = `${NAMESPACE} Get Open Platforms`;
  }

  export class SelectRegistrationPlatform {
    public static readonly type = `${NAMESPACE} Select Platform`;

    public constructor(public platformId: number) {
    }
  }
}
