import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
// noinspection ES6PreferShortImport
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';

@Injectable()
export class LoadingOverlayService {

  private _overlayRef: OverlayRef | null = null;

  public constructor(private overlay: Overlay) {
  }

  public open(): void {
    if (this._overlayRef) {
      return;
    }

    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const config: OverlayConfig = new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    this._overlayRef = this.overlay.create(config);
    this._overlayRef.attach(new ComponentPortal(LoadingOverlayComponent));
  }

  public close(): void {
    if (!this._overlayRef) {
      return;
    }

    this._overlayRef.dispose();
    this._overlayRef = null;
  }
}
