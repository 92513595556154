import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from '@sharesafe/api/core';

import { SSO_API_URL } from '../../constants/injection-tokens';
import { PasswordReset, PasswordResetMethods } from '../../..';
import { PasswordResetRequirements } from '../../models/password-reset-requirements';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetApiService extends BaseApiService {
  private static readonly BasePath = 'api/password-reset';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getPasswordRequirements(): Observable<PasswordResetRequirements> {
    const url = this.buildUrl(this.baseUrl, `${PasswordResetApiService.BasePath}/requirements`);

    return this.httpClient.get<PasswordResetRequirements>(url);
  }

  public getPasswordResetMethods(username: string): Observable<PasswordResetMethods> {
    const url = this.buildUrl(this.baseUrl, `${PasswordResetApiService.BasePath}/user/${username}/methods`);

    return this.httpClient.get<PasswordResetMethods>(url);
  }

  public sendPasswordResetEmail(email: string): Observable<any> {
    return this.sendPasswordReset(email, 'email');
  }

  public sendPasswordResetSms(phone: string): Observable<any> {
    return this.sendPasswordReset(phone, 'sms');
  }

  private sendPasswordReset(username: string, method: string): Observable<any> {
    const url = this.buildUrl(this.baseUrl, `${PasswordResetApiService.BasePath}/user/${username}/${method}`)

    return this.httpClient.post(url, null);
  }

  public resetPassword(token: string, password: string): Observable<any> {
    const url = this.buildUrl(this.baseUrl, PasswordResetApiService.BasePath);
    const model: PasswordReset = {
      token: token,
      password: password
    };

    return this.httpClient.post(url, model);
  }
}
