import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  public constructor(private breakpointObserver: BreakpointObserver) {
  }

  public get isSmallScreen$(): Observable<boolean> {
    return this.breakpointObserver.observe('(max-width: 599px)')
      .pipe(
        map(result => result.matches)
      );
  }

  public get isLargeScreen$(): Observable<boolean> {
    return this.breakpointObserver.observe('(min-width: 600px)')
      .pipe(
        map(result => result.matches)
      );
  }
}
