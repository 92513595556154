import { Inject, Injectable } from '@angular/core';
import { BaseApiService } from '@sharesafe/api/core';
import { SSO_API_URL } from '../../constants/injection-tokens';
import { AccountRecoveryProvidersModel } from '../../models/account-recovery-providers-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RecoveryClient extends BaseApiService {
  private static readonly BasePath = 'api/recovery';

  public constructor(@Inject(SSO_API_URL) private baseUrl: string,
                     private httpClient: HttpClient) {
    super();
  }

  public getAccountRecoveryProviders(username: string): Observable<AccountRecoveryProvidersModel> {
    const url = this.buildUrl(this.baseUrl, `${RecoveryClient.BasePath}/providers`, { userName: username });

    return this.httpClient.get<AccountRecoveryProvidersModel>(url);
  }

  public sendPasswordResetEmail(username: string): Observable<void> {
    const url = this.buildUrl(this.baseUrl, `${RecoveryClient.BasePath}/providers/email`, { userName: username });

    return this.httpClient.post<void>(url, null);
  }

  public sendPasswordResetSms(username: string): Observable<void> {
    const url = this.buildUrl(this.baseUrl, `${RecoveryClient.BasePath}/providers/phone`, { userName: username });

    return this.httpClient.post<void>(url, null);
  }

  public resetPasswordWithToken(provider: string, token: string, username: string, password: string): Observable<void> {
    const url = this.buildUrl(this.baseUrl, `${RecoveryClient.BasePath}/reset-password`);

    console.log(url)

    return this.httpClient.post<void>(url, {
      provider: provider,
      token: token,
      userName: username,
      password: password
    });
  }
}
