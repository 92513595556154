import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationApiService } from './services/authentication/authentication-api.service';
import { OpenidConnectApiService } from './services/openid-connect/openid-connect-api.service';
import { ClientsApiService } from './services/clients/clients-api.service';
import { AuthenticationSystemsApiService } from './services/authentication-systems/authentication-systems-api.service';
import { PasswordResetApiService } from './services/password-reset/password-reset-api.service';
import { UsersApiService } from './services/users/users-api.service';
import { SsoApiConfig } from './models/sso-api.config';
import { SSO_API_CLIENT_ID, SSO_API_URL } from './constants/injection-tokens';
import { AuthenticationClient } from './services/authentication/authentication-client';
import { RecoveryClient } from './services/password-reset/recovery-client';

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthenticationApiService,
    AuthenticationSystemsApiService,
    ClientsApiService,
    OpenidConnectApiService,
    PasswordResetApiService,
    UsersApiService,
    AuthenticationClient,
    RecoveryClient
  ]
})
export class ApiSsoModule {

  public static forChild(config: SsoApiConfig): ModuleWithProviders<ApiSsoModule> {
    return {
      ngModule: ApiSsoModule,
      providers: [
        { provide: SSO_API_URL, useValue: config.baseUrl },
        { provide: SSO_API_CLIENT_ID, useValue: config.clientId }
      ]
    };
  }
}
