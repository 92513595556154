import { Selector } from '@ngxs/store';
import { IdentityState } from './identity.state';
import { IdentityStateModel } from './identity.state-model';
import * as moment from 'moment';
import { PlatformOverview } from '@sharesafe/api/sso';
import { RegistrationPlatform } from '../../models';

export class IdentitySelectors {

  @Selector([IdentityState])
  public static expired(state: IdentityStateModel): boolean {
    if (!state.expires) {
      return true;
    }

    return moment().utc().isSameOrAfter(state.expires);
  }

  @Selector([IdentityState])
  public static hasTokens(state: IdentityStateModel): boolean {
    return !!state.accessToken && state.accessToken.length > 0
      && !!state.idToken && state.idToken.length > 0
      && !!state.refreshToken && state.refreshToken.length > 0;
  }

  @Selector([IdentitySelectors.hasTokens, IdentitySelectors.expired])
  public static valid(hasTokens: boolean, expired: boolean): boolean {
    return hasTokens && !expired;
  }

  @Selector([IdentityState])
  public static accessToken(state: IdentityStateModel): string {
    return state.accessToken ?? '';
  }

  @Selector([IdentityState])
  public static idToken(state: IdentityStateModel): string | undefined {
    return state.idToken;
  }

  @Selector([IdentityState])
  public static refreshToken(state: IdentityStateModel): string | undefined {
    return state.refreshToken;
  }

  @Selector([IdentityState])
  public static platforms(state: IdentityStateModel): PlatformOverview[] {
    return state.platforms;
  }

  @Selector([IdentityState])
  public static preferredPlatformId(state: IdentityStateModel): number | undefined {
    return state.preferredPlatformId;
  }

  @Selector([IdentityState])
  public static registrationPlatforms(state: IdentityStateModel): PlatformOverview[] {
    return state.registration.openPlatforms;
  }

  @Selector([IdentityState])
  public static filteredRegistrationPlatforms(state: IdentityStateModel): RegistrationPlatform[] {
    return state.registration.openPlatforms
      .filter(op => !state.platforms.find(p => p.platformId === op.platformId))
      .map(p => {
        return {
          platform: p,
          selected: !!state.registration.selected.find(s => s === p.platformId)
        };
      });
  }

  @Selector([IdentitySelectors.filteredRegistrationPlatforms])
  public static registrationPlatformsSelected(platforms: RegistrationPlatform[]): PlatformOverview[] {
    return platforms.filter(p => p.selected).map(p => p.platform);
  }

  @Selector([IdentityState])
  public static registrationPlatformSelectDisabled(state: IdentityStateModel): boolean {
    return state.registration.selected.length < 1;
  }
}
