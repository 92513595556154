import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationService} from '../../services/notification/notification.service';

@Injectable()
export class NotificationDismissInterceptor implements HttpInterceptor {

  public constructor(private notificationService: NotificationService) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.notificationService.close();

    return next.handle(request);
  }
}
