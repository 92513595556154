import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { map, skip, startWith, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationActions } from '@sharesafe/shared';

interface Dictionary {
  [key: string]: string
}

@Component({
  selector: 'singlesignon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private readonly _subscription: Subscription = new Subscription();

  public constructor(public loadingBarService: LoadingBarService,
                     private route: ActivatedRoute,
                     private router: Router,
                     private store: Store) {
  }

  public ngOnInit(): void {
    const subscription = this.route.queryParams
      .pipe(
        skip(1),
        startWith(this.getQueryParams()),
        take(1),
        map(params => this.filterQueryParams(params)),
        switchMap(params => this.store.dispatch(new ApplicationActions.SetQueryParams(params)))
      ).subscribe();

    this._subscription.add(subscription);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private filterQueryParams(params: Dictionary): Dictionary {
    return Object.keys(params)
      .filter(key => key.toLowerCase() !== 'token')
      .reduce<Dictionary>((prev, key) => {
        prev[key] = params[key];

        return prev;
      }, {});
  }

  private getQueryParams(): Dictionary {
    if (window.location.search.length === 0) {
      return {};
    }

    return window.location.search
      .substr(1)
      .split('&')
      .map(pairString => pairString.split('='))
      .filter(pair => pair[0] !== 'token')
      .reduce((out: Dictionary, pair) => {
        const key = pair[0];

        out[key] = decodeURIComponent(pair[1]);

        return out;
      }, {});
  }
}
